.MeetTheExperts {
  background-color: #F7F7F7;
  color: black;

  .page-content {
    padding: 100px 0;

    @media screen and (max-width: 400px) {
      padding: 50px 0;
    }
  }

  h2 { font-size: 4rem; text-align: center; text-transform: uppercase;}

  .expert-slides {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ExpertSlide__title {
    span {
      &:not(:last-child)::after {
        content: ', ';
      }
    }
  }

  .ExpertSlide {
    width: 277px;
    background-color: #282828;
    color: white;
    margin: 30px;
    cursor: pointer;
    position: relative;

    *, & {box-sizing: border-box;}



    .ExpertSlide__content {
      padding: 10px 50px 10px 30px;
      height: 195px;

      // font-size: 14px;


      h3 {
        font-size: 22px;
        margin: 0 0 15px 0;
      }
    }

    .ExpertSlide__link {
      position: absolute;
      bottom: 20px;
      left: 30px;
      right: 0;
      text-decoration: underline;
      transition: all .3s ease-in-out;
    }

    img {
      width: 100%;
    }
    h3 {
      font-size: 1rem;
      margin: 0;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.ExpertSlide__bio {
  margin: 12px 0;
  max-width: 520px;
  line-height: 25px;
}

.ExpertSlide__modal {
  .ExpertSlide__title {
    max-width: 300px;
    span {
      &:not(:last-child)::after {
        content: ', ';
      }
    }
  }
}