body {
  overflow-x: hidden;
}

.TechSummit2023 {
  margin-top: 40px;

  .page-intro {
    font-weight: 800;
    font-size: 2em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 7px;
    font-family: JDSansProCond;
  }

  .TeaserHero {
    position: relative;
  }

  .TeaserHero__video {
    width: 100%;
  }

  .TeaserHero__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10%;

    svg {
      width: 100%;
      max-width: 600px;
    }
  }

  .YellowHr {
    height: 2px;
    max-width: 455px;
    margin: 3rem auto;
    background: var(--yellow);
  }
}

.KeynoteCallout {
  background-color: var(--light-gray);
  background-size: cover;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 90%;
  max-width: 1200px;
  margin-top: 80px;
  margin-bottom: 60px;

  &__logo {
    width: 50%;
    display: flex;
    justify-content: center;

    svg {
      width: 80%;
    }
  }

  &__content {
    width: 50%;
    flex-grow: 0;
    backdrop-filter: blur(14px) brightness(0.4);
    height: 100%;
    padding: 5% 6%;
    color: white;
    box-sizing: border-box;
    font-family: JDSansProCond;
    background: var(--green);

    h2 {
      font-size: 40px;
      font-weight: bold;
      margin: 0;
    }

    h3 {
      font-size: 30px;
      font-family: JDSansProCond;
      font-weight: bold;
      text-align: left;
      margin: 0;
    }

    p {
      text-align: left;
    }
  }

  @media screen and (max-width: 1024px) {
    &__content {
      padding: 3%;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;
    flex-direction: column;
    margin-top: 40px;
    max-height: initial;

    .KeynoteCallout__logo {
      width: 100%;
      height: 65vw;
    }

    .KeynoteCallout__content {
      width: 100%;

      &, h2, h3, p {
        text-align: center;
      }
    }
  }
}

.PadPanel {
  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
}

